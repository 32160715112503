
export default {
    formatDateToLocal(dateTime, locale = 'default') {
        const date = new Date(dateTime);
        return date.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
    },
    toStringOrDefault(value, defaultText = "N/A") {
        if (value === null || value === undefined) {
            return defaultText;
        }
        return String(value);
    }
    
}