const state = {
    errorMessage: "",
};

const mutations = {
    SET_ERROR(state, message) {
        state.errorMessage = message;
    },
    CLEAR_ERROR(state) {
        state.errorMessage = "";
    },
};


const actions = {
    triggerError({ commit }, message) {
        commit("SET_ERROR", message);
        setTimeout(() => commit("CLEAR_ERROR"), 5000); // Auto-clear after 5 sec
    },
};
const getters = {
    errorMessage: (state) => state.errorMessage,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
