import { createStore } from 'vuex'
import error from "./modules/error"; // Import the error module
import loader from "./modules/loader"; // Import the error module
const store = createStore({
    state: {
        main_logo_path: '@/assets/brand/spec-brand-1.svg',
        favicon_logo_path: '',
        primary_color: '',
        second_color: '',
        isPageLoad: true,
    },
    getters: {
        getMainLogo: state => state.main_logo_path,
        getLogoIcon: state => state.favicon_logo_path,
        getPrimaryColor: state => state.primary_color,
        getSecondaryColor: state => state.second_color,
        getPageLoad: state => state.isPageLoad
    },
    mutations: {
        loadPage (state, pageLoad = false) {
            console.log("setting page load to false")
            state.isPageLoad = pageLoad
        }
    },
    actions: {
        updatePageLoad({ commit }, newLoad) {
            commit('loadPage', newLoad);
        },
    },
    modules: {
        error,
        loader
    },
});

export default store;
