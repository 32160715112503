/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
//import LandingView from "../views/public/LandingView.vue";
import DashboardView from "../views/private/DashboardView.vue";
import ModuleView from "../views/private/ModuleView.vue";
import ModulePreView from "../views/private/ModulePreView.vue";
import ResourceView from "../views/private/ResourceView.vue";
import SampleView from "../views/private/SampleView.vue";
import CertificateView from "../views/private/CertificateView.vue";
import RDVView from "../views/private/RDVView.vue";
import QuizView from "../views/private/QuizView.vue";
import CertificateTakeRoomView from "../views/private/CertificateTakeRoomView.vue";
import CompanyView from "../views/private/CompanyView.vue";
import UserProgressionView from "../views/private/UserProgressionView.vue";
import UserManagmentView from "../views/private/UserManagmentView.vue";
import PhishingView from "../views/private/PhishingView.vue";
import ParameterView from "../views/private/ParameterView.vue";
import FacturationView from "../views/private/FacturationView.vue";
import WebContainerView from "../views/private/WebContainerView.vue";
import WebContainerView1 from "../views/private/WebContainerView.vue";
import PdfContainerView from "../views/private/PdfContainerView.vue";
import CampaignDetails from '../views/private/campaignDetails.vue';
import CampaignDetailsSMS from '../views/private/campaignDetailsSMS.vue';
// import LoginView from "../views/public/auth/LoginView.vue";
import RegistrationView from "../views/public/auth/RegistrationView.vue";
// import AccountActivationView from "../views/public/auth/AccountActivationView.vue";
// import PasswordForgetView from "../views/public/auth/PasswordForgetView.vue";
// import PasswordResetView from "../views/public/auth/PasswordResetView.vue";
// import ResendActivationView from "@/views/public/auth/ResendActivationView.vue";
import ConditionsView from "@/views/public/ConditionsView.vue";
import MentionsView from "@/views/public/MentionsView.vue";

import { i18n } from "@/main";
import RegistrationWithCodeView from "@/views/public/auth/RegistrationWithCodeView.vue";
import phishingDetailsView from "@/views/private/PhishingDetailsView.vue";
import UnlockView from "@/views/public/auth/UnlockView.vue";
import CheckCertificateView from "@/views/public/CheckCertificateView.vue";
import SupportView from "@/views/private/SupportView.vue";
import MicroLearning from "../views/private/Micro-learning.vue";
import SupportDetailView from "@/views/private/SupportDetailView.vue";
import PricingView from "@/views/private/PricingView.vue";
import MicroLearningDetails from "../views/private/MicroLearningDetails.vue";
import Signin from '../views/auth/Sign-in.vue';
import SignUp from '../views/auth/Sign-Up.vue';
import Register from '../views/auth/Register.vue';
import ForgotPassword from '../views/auth/forgot-password.vue';
import NewForgotPassword from '../views/auth/NewForgotPassword.vue';
//import ResetPassword from '../views/auth/resetPassword.vue';
import NewPasswordReset from '../views/auth/NewPasswordReset.vue';
import ResendActivation from '../views/auth/ResendActivation.vue';
import AccountActivation from '../views/auth/AccountActivation.vue';
import Registerwithcode from '../views/auth/RegisterWithCode.vue';
import AcceptInvite from '../views/auth/Accept-invite.vue';
import NewAcceptInvite from '../views/auth/NewAcceptInvite.vue';
import MicroLearningTest from '../views/private/MicroLearning.vue';
import MicroCampaign from '../views/private/MicroCampaign.vue';
import CreateCampaign from '../views/private/CreateCampaign.vue';
import SmshingDetailsView from "@/views/private/SmshingDetailsView.vue";
import Login from "@/views/auth/Login.vue";
import NewAccountActivation from "@/views/auth/NewAccountActivation.vue";
import NewRegisterWithCode from "@/views/auth/NewRegisterWithCode.vue";
import AuditlogView from '../views/private/AuditLogs.vue';
const ifAuthenticated = (to, from, next) => {
  // const redirectPath = localStorage.getItem('currentPath');
  // const name = redirectPath.startsWith('/') ? redirectPath.slice(1) : redirectPath;

  if (localStorage.getItem("token") != null) {
    next();
    return;
  }
  next("/?loginRequired=true");
};

const notAuthenticated = (to, from, next) => {
  //const redirectPath = localStorage.getItem('currentPath');
  if (localStorage.getItem("token") == null) {
    next();
    return;
  }
  next("/dashboard");
};

const routes = [
  {
    path: "/",
    //name: "Landing",
    // redirect: "login",
    //component: Login,
    //component: Signin,
    //beforeEnter: notAuthenticated,
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/login', query: { ...to.query } }
    },
  },
  {
    path: "/unlock/:email",
    name: "Unlock",
    component: UnlockView,
    beforeEnter: notAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    //component: Signin,
    component: Login,
    beforeEnter: notAuthenticated,

    
  },
  {
    path: "/sign-up",
    name: "Registration",
    //component: RegistrationView,
    component: Register,
    beforeEnter: notAuthenticated,
  },
  {
    path: "/sign-up-with-code",
    name: "RegistrationWithCode",
    component: RegistrationWithCodeView,
    beforeEnter: notAuthenticated,
  },
  {
    path: "/activate",
    name: "AccountActivationview",
    //component: AccountActivation,
    component: NewAccountActivation,
    beforeEnter: notAuthenticated,
  },
  /*{
    path: "/new-activate",
    name: "NewAccountActivationview",
    component: NewAccountActivation,
    beforeEnter: notAuthenticated,
  },*/
  {
    path: "/accept-invite/:token/:uid",
    name: "Accept-invite",
    //component: AcceptInvite,
    component: NewAcceptInvite,
    beforeEnter: notAuthenticated,
  },
  {
    path: "/resend-activation-mail",
    name: "AccountActivationResent",
    component: ResendActivation,
    beforeEnter: notAuthenticated,
  },
  {
    path: "/password-forget",
    name: "PasswordForget",
    //component: ForgotPassword,
    component: NewForgotPassword,
    beforeEnter: notAuthenticated,
  },
  {
    path: "/auditlog",
    name: "Audit logs",
    component: AuditlogView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/password-reset",
    name: "ResetPassword",
    //component: ResetPassword,
    component: NewPasswordReset,
    beforeEnter: notAuthenticated,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/modules",
    name: "Module",
    component: ModuleView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/micro-campaign",
    name: "MicroCampaign",
    component: MicroCampaign,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/microlearn",
    name: "Microlearn",
    component: MicroLearningTest,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/micro-learning-details/:id',
    name: 'Micro-Learning-details',
    component: MicroLearningDetails,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/modules-details/:id",
    name: "ModulePreview",
    component: ModulePreView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/campaign-details/:id",
    name: "CampaignDetails",
    component: CampaignDetails,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/campaign-details-sms/:id",
    name: "CampaignDetailsSMS",
    component: CampaignDetailsSMS,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/modules/courseroom/:id/",
    name: "ModuleIframe2",
    component: WebContainerView1,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/modules/courseroom/:id/:slug/",
    name: "ModuleIframe",
    component: WebContainerView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/ressources",
    name: "Resources",
    component: ResourceView,
    beforeEnter: ifAuthenticated,
  },
    {
    path: "/createCampaign/:id",
    name: "CreateCampaign",
    component: CreateCampaign,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/Micro-learning/information-campaign",
    name: "MicroLearning",
    component: MicroLearning,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/ressources/content/:id/:slug/",
    name: "ResourcesIframe",
    component: PdfContainerView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/certificate-exam",
    name: "Quiz",
    component: QuizView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/quiz/take-exam/:id",
    name: "ExamIframe",
    component: CertificateTakeRoomView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/mon-certificat",
    name: "Certificate",
    component: CertificateView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/rendez-vous-expert",
    name: "RDV",
    component: RDVView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/entreprise",
    name: "Entreprise",
    component: CompanyView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/suivi-utilisateurs",
    name: "UserProgress",
    component: UserProgressionView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/gestion-utilisateurs",
    name: "UserManage",
    component: UserManagmentView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/parametres",
    name: "Setting",
    component: ParameterView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/facturation",
    name: "Facturation",
    component: FacturationView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/facturation/pricing",
    name: "FacturationPricing",
    component: PricingView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/support-service",
    name: "Support",
    component: SupportView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/support-service/:id",
    name: "SupportDetail",
    component: SupportDetailView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/campagne-de-phishing",
    name: "Phishing",
    component: PhishingView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/campagne-de-phishing/details/:id",
    name: "PhishingDetails",
    component: phishingDetailsView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/campagne-de-smshing/details/:id",
    name: "SmshingDetails",
    component: SmshingDetailsView,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/logout",
    name: "Logout",
    component: SampleView,
  },
  {
    path: "/mentions",
    name: "Mentions",
    component: MentionsView,
  },
  {
    path: "/generals-conditions",
    name: "Conditions",
    component: ConditionsView,
  },
  {
    path: "/certificate",
    name: "CheckCertificate",
    component: CheckCertificateView,
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/errors/404.vue"),
  },
  {
    path: '/signin',
    name: 'Sign-in',
    component: Signin,
    beforeEnter: notAuthenticated,
  },
  {
    path: '/signup',
    name: 'Sign-up',
    //component: SignUp,
    component: Register,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
/*  {
    path: '/new-signin',
    name: 'Login',
    component: Login,
  },*/
  {
    path: '/forgotpassword',
    name: 'forgot-password',
    //component: ForgotPassword,
    component: NewForgotPassword,
    beforeEnter: notAuthenticated,
  },
  {
    path: '/resetpassword',
    name: 'resetPassword',
    //component: ResetPassword,
    component: NewPasswordReset,
    beforeEnter: notAuthenticated,
  },
  {
    path: '/Resend-Activation-Email',
    name: 'resendActivationEmail',
    component: ResendActivation,
    beforeEnter: notAuthenticated,
  },
  {
    path: '/ActivateAccount',
    name: 'accountActivation',
    component:AccountActivation,
    beforeEnter: notAuthenticated,
  },
  /*{
    path: '/register-with-code',
    name: 'registerwithCode',
    component:Registerwithcode,
    beforeEnter: notAuthenticated,
  },*/
  {
    //path: '/code-register',
    path: '/register-with-code',
    name: 'newregisterwithCode',
    component:NewRegisterWithCode,
    beforeEnter: notAuthenticated,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "cfa-link-active",
  linkExactActiveClass: "cfa-link-exact-active",
  routes,
});

router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem("language") || "fr";
  if (!to.matched.length) {
    next("/404");
  }
  next();
});

export default router;
