const state = {
    isLoading: true,
};

const mutations = {
    SET_LOADING(state, load) {
        state.isLoading = load;
    },
    CLEAR_LOADING(state) {
        state.errorMessage = false;
    },
};


const actions = {
    triggerLoading({ commit }, load) {
        commit("SET_LOADING", load);
        //setTimeout(() => commit("CLEAR_ERROR"), 5000); // Auto-clear after 5 sec
    },
};
const getters = {
    isLoading: (state) => state.isLoading,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
