<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString">
      <template v-slot:title>
        
      <div v-if="(subscriptionData?.data && !subscriptionData?.data?.is_activated) || subscriptionData == null" class="card-body p-0 mt-4 mb-0">
        
          <div class="card-transparent cfa-warning-deadline py-3 px-2" style="border: 2px solid rgb(50,204,218); background-color: rgb(229,245,245); color: rgb(50,204,218);font-weight: 400;" v-if="!waitingAPIResponse && userRole == 'manager'">
              <div class="d-flex align-items-center justify-content-between w-100" >
                  <div>
                      {{ $t('follow_modules') }}
                  </div>
                  <div class="">
                      <a href="/facturation/pricing"><button class="btn cfa-btn-primary">{{ $t('subscribe') }}</button></a>
                  </div>
              </div>
              
          </div>
          
      </div>
        <div class="w-100 pt-5 text-start mb-3">
          <h1 class="cfa-section-title">MODULES</h1>
        </div>
      </template>
      <template v-slot:content>
      <div class="cfa-welcome">
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <div >
            <content-filter @viewMode="getViewMode" @viewFilter="getViewFilter" :total1="totalModule" :total2="module_covered"></content-filter>
          </div>
          <div v-if="this.allModules.length == 0" class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="d-flex flex-column">
              <img v-if="this.organisationLogo" :src="this.organisationLogo" alt="empty content" style="width: 15em;"/>
              <img v-else src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 15em;"/>
              <span class="mt-3 fs-5 fw-bold">{{$t('nothing_yet')}}</span>
            </div>
          </div>
          <div v-else>
            <div class="row pt-3" style="color: #132B4D;">
              <course-card @error="getModuleError" v-for="module, i in this.allModules" v-bind:key="i" :id="module.id" :type="module.category" :preview="module.module_image" :label="'Module '+(i+1)" :finished="(module.statut != 'available') ? false : false" :started="(i!=0) ? false : true" :title="module.title" :description="module.resume" :max__horaire="module.duration" :viewMode='displayMode' :filterMode="filterMode"></course-card>
            </div>
          </div>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center oups" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/brand/cfa-favicon1.png" alt="cfa preloader">
              <p class="pl-3 fw-bold">chargement . . .</p>
            </div>
          </div>
        </div>
        </div>
        <!-- <pre-loader v-show="waitingAPIResponse"/> -->
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
/* eslint-disable */
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import ContentFilter from '@/components/shared/ContentFilter.vue'
import CourseCard from '@/components/shared/course/CourseCard.vue'
import { getAPI } from '@/axios-api.js'
// import PreLoader from '@/components/shared/PreLoader.vue'
import { useStore } from "vuex";

export default {
  name: 'DashboardView',
  setup () {
    const store = useStore()

  },
  components: {
    CFAFooter,
    CFANav,
    ContentFilter,
    CourseCard,
    // PreLoader,
  },
  data () {
    return {
      waitingAPIResponse: true,
      displayMode: 'GROUP',
      filterMode: 'ALL',
      allModules: [],
      alertString: null,
      totalInProgress: 0,
      totalModule: 0,
      module_covered: 0,
      module_progress: [],
      subscriptionData: [],
      organisationLogo: '',
      userRole: localStorage.getItem("role")
    }
  },
  methods: {
    getViewMode (e) {
      this.displayMode = e
    },
    getViewFilter (e) {
      this.filterMode = e
    },
    getModuleError (e) {
    console.log(e)
      //this.alertString = `${e}|alert-no|10000::`+Date.now()
    },
    retrieveUserSubscription () {
      this.waitingAPIResponse = true
      getAPI.get(`subscriptions/find-subscription`).then(response => {
        console.log(response)
        if(response.status == 200) {
          this.subscriptionData = response.data;
          this.organisationLogo = this.subscriptionData.data.organisation.logo;
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        setTimeout(() => {
          this.waitingAPIResponse = false
        }, 2000);
      }).catch(error => {
      this.subscriptionData = error.data
        if (error.response) {
          //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          //let msg = 'The request was made but no response was received. Please check your network.'
          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          //let msg = 'Oups ! something went wrong.'
          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.waitingAPIResponse = false
      })
    },
  },
  async mounted () {
    this.retrieveUserSubscription()
    console.log(this.$route)
    if (this.$route.query.reason) {
      if (this.$route.query.reason == "no_subscription") {
        //
        this.$swal({
            title: 'Aucune souscription',
            text: `Veuillez vous souscrire a une formule`,
            type: 'warning',
            showCancelButton: false,
            confirmButtonText: "Fermer"
        });
      } else if (this.$route.query.reason == "unfinished_modules") {
        this.$swal({
            title: 'Modules',
            text: `Veuillez finir les modules pour passer l'examen`,
            type: 'warning',
            showCancelButton: false,
            confirmButtonText: "Fermer"
        });
      }
    }
    this.waitingAPIResponse = true
    getAPI.get('modules/list_module_visible')
    .then(response => {
      console.log(response)
      if(response.status == 200) {
        let modulesData = response.data
        this.allModules = modulesData.data
        this.totalModule = modulesData.data.length
        // TODO: make this dynamic
        this.totalInProgress = 1
      }
      else {
        //let msg = 'Oups ! something went wrong.'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      setTimeout(() => {
        this.waitingAPIResponse = false
      }, 2000);
    })
    .catch(error => {
      if (error.response) {
        //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
      }
      else if (error.request) {
        //let msg = 'The request was made but no response was received. Please check your network.'
        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
      }
      else {
        //let msg = 'Oups ! something went wrong.'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      this.waitingAPIResponse = false
    })

    this.waitingAPIResponse = true
    getAPI.get(`stat_module?user_id=${localStorage.user}`).then(response => {
      console.log(response)
      if(response.status == 200) {
        let progress = response.data
        this.module_covered = progress.length
        for (let i = 0; i < progress.length; i++) {
          this.module_progress.push({module: progress[i].number, progress: progress[i].value })
        }
        for (let i = this.module_progress.length; i < 12; i++) {
          this.module_progress.push({module: i+1, progress: 0 })
        }
      }
      else {
        //let msg = 'Oups ! something went wrong.'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      setTimeout(() => {
        this.waitingAPIResponse = false
      }, 2000);
    }).catch(error => {
      if (error.response) {
        //this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
      }
      else if (error.request) {
        //let msg = 'The request was made but no response was received. Please check your network.'
        //this.alertString = `${msg}|alert-no|8000::`+Date.now()
      }
      else {
        //let msg = 'Oups ! something went wrong.'
        //this.alertString = `${msg}|alert-no|5000::`+Date.now()
      }
      this.waitingAPIResponse = false
    })
  },
  
}
</script>

<style scoped>

</style>
