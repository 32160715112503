<template>
    <div class="page-auth">
        <div class="login-page">
            <div class="hidden bg-transparent lg:flex flex-col items-center justify-center">
                
                <p class="text-white text-4xl text-center w-5/6">{{displayedText}} <span class="cursor" v-if="showCursor">|</span></p>
            </div>
            <div class="content-form flex flex-col items-center justify-center py-4 bg-transparent">
                <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15">
                    <div class="grid gap-2 text-center  justify-items-center">
                        <div class="w-3/4 mb-3">
                            <img class="" src="../../assets/auth/images/spec-brand-1.svg"
                            alt="cyberforceacademy logo"  >
                        </div>
                        <!-- <h1 class="text-4xl font-[500] text-spect">
                            Connexion
                        </h1> -->
                        <!-- <p class="text-balance">
                            Entrez vos informations pour  vous  connecter
                        </p> -->
                        <form class="grid gap-3 px-4" @submit.prevent="handleSubmit">
                            <div>
                                <label class="block font-medium text-left text-gray-700 mb-1">Email</label>
                                <input
                                type="email"
                                v-model="formData.email"
                                class="w-full px-4 py-2 input input-bordered ui-input"
                                :class="{ 'ui-input--error': errors.email }"
                                placeholder="Entrez votre adresse email"
                                />
                                <p v-if="errors.email" class="text-red-500 text-sm mt-1 text-left">{{ errors.email }}</p>
                            </div>

                            <!-- Password -->
                            <div>
                                <label class="block text-left font-medium text-gray-700 mb-1">Mot de passe</label>
                                <div class="relative">
                                    <input
                                    :type="toggle.password ? 'text' : 'password'"
                                    v-model="formData.password"
                                    class="w-full px-4 py-2 input input-bordered ui-input"
                                    placeholder="mot de passe"
                                    :class="{ 'ui-input--error': errors.password }"
                                    />
                                    <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.password = false" v-if="toggle.password" ><i class="fa fa-eye-slash"></i></span>
                                    <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.password = true" v-if="!toggle.password" ><i class="fa fa-eye"></i></span>
                                </div>
                                <p v-if="errors.password" class="text-red-500 text-sm mt-1 text-left">{{ errors.password }}</p>
                                <div class="flex justify-end items-center">
                                    <!-- <label class="label cursor-pointer gap-1">
                                        <input type="checkbox" :checked="'checked'" class="" />
                                        <span class="label-text">Se rappeler de moi</span>
                                    </label> -->
                                    <a href="/forgotpassword" class="forgot-password label-text text-accent"> Mot 
                                        de passe oublié ? </a>
                                </div>
                            </div>
                            <button
                                type="submit"
                                class="w-full flex items-center justify-center gap-3 bg-spect text-white py-3 rounded-6 hover:bg-opacity-90 transition-colors btn-disable" :disabled="isInvalid || isSubmit"
                            >
                                <span class="loading loading-spinner" v-if="isSubmit" ></span>
                                <span>
                                    Connexion
                                </span>
                            </button>
                            <div class="grid gap-1 text-sm">
                                <div class="text-center text-sm">
                                    <span class="text-gray-600">Vous n'avez pas un compte? </span>
                                    <a href="/signup" class="text-accent">Inscrivez-vous ici</a>
                                </div>
                                <p class="text-center">
                                    ou
                                </p>
                                <div class="text-center text-sm">
                                    <a href="/Register-with-code" class="cursor-pointer text-accent" >
                                        <span>S'inscrire avec un code</span>
                                    </a>
                                        
                                </div>
                                
                                
                            </div>
                            <div class="flex items-center justify-center">
                                
                                <span class="ml-2 text-sm text-gray-600">
                                    En continuant, vous acceptez les termes de notre <a href="https://cyberspector.com/politique-de-confidentialite-et-de-protection-des-donnees/" target="_blank" class="text-accent">politique de confidentialité</a>
                                    et nos réglementations concernant les <a href="https://cyberspector.com/politique-de-confidentialite-et-de-protection-des-donnees/" target="_blank" class="text-accent">cookies</a> 
                                    
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="toast toast-end my-toast" v-if="isAlert">
            <div class="alert alert-info toast-ctn--info">
                <span>{{ alertMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import { getAPI } from '@/axios-api.js'

export default {
    name: 'LoginView',
    data() {
        return {
            toggle: {
                password: false
            },
            formData: {
                accountType: 'personal',
                email: '',
                password: '',
                
            },
            errors: {
                email: null,
                password: null,
                
            },
            isSubmit: false,
            isInvalid: false,
            isAlert: false,
            alertMessage: "New message",
            txt: 'Placez l’humain au coeur de votre Stratégie de Cyberdéfense', // Text to display
            displayedText: '', // Currently displayed text
            speed: 100, // Typing speed in milliseconds
            backspaceSpeed: 50, // Backspace speed
            i: 0, // Character index
            showCursor: true, // Cursor blinking
            isDeleting: false, // Backspace mode flag
        }
    },
    methods : {
        validateStep2() {
            // Add validation logic for step 2
            this.errors.email = null
            this.errors.password = null
            let isValid = true

            if (!this.formData.email || this.formData.email.trim() == "") {
                this.errors.email = 'Le champ email est obligatoire.'
                isValid = false
            }
            if (!this.formData.password || this.formData.password.trim() == "") {
                this.errors.password = 'Le champ mot de passe est obligatoire.'
                isValid = false
            }
            return isValid
        },
        async handleSubmit () {
    if (this.validateStep2()) {
        console.log("loginn");
        this.isSubmit = true;
        await axios.post(`${process.env.VUE_APP_BASE_URL}accounts/login`, {
            email: this.formData.email,
            password: this.formData.password,
        })
        .then(async (response) => {
            console.log(response);
            if (response.status == 200) {
                const token = response.data.data.access;
                const refresh_token = response.data.data.refresh;
                const user_data = response.data.data.user;

                // Store user details in localStorage
                localStorage.setItem('token', token);
                localStorage.setItem('refresh_token', refresh_token);
                localStorage.setItem('user', user_data.id);
                localStorage.setItem('firstname', user_data.firstname);
                localStorage.setItem('lastname', user_data.lastname);
                localStorage.setItem('organisation', user_data.organisation);
                localStorage.setItem('type_account', user_data.type_account);
                localStorage.setItem('role', user_data.role);
                localStorage.setItem('image', null);

                // Fetch org color

                try {
                    const response = await getAPI.get(`organisations/${localStorage.organisation}/`);
                    if (response.status === 200) {
                        
                        const primary = response.data.primary_color;
                        const secondary = response.data.secondary_color;

                        // Store colors in localStorage
                        localStorage.setItem("primary_color", primary);
                        localStorage.setItem("secondary_color", secondary);
                    }
                } catch (error) {
                    console.error("Error fetching organization data:", error);
                }

                // Get the redirectPath
                let redirectPath = localStorage.getItem('currentPath') || '/dashboard';

                // Check if redirectPath is '/login' and redirect to '/dashboard'
                if (redirectPath === '/login') {
                    redirectPath = '/dashboard';
                }

                // Perform redirection
                this.$router.push(redirectPath);

                /*setTimeout(() => {
                    this.isSubmit = false;
                    let a = document.createElement('a');
                    a.href = redirectPath;
                    a.click();
                    a.remove();
                }, 1000);*/
            } else {
                this.isSubmit = false;
            }
        })
        .catch(error => {
            console.log(error);
            localStorage.removeItem('token');
            if (error.response) {
                if (error.response.data.message === "Invalid Credentials") {
                    this.errors.email = "Adresse email ou mot de passe incorrect(e)";
                } else if (error.response.data.message === "Your account has been blocked. Please check your email for verification code.") {
                    this.errors.email = "Votre compte a été bloqué. Veuillez vérifier votre messagerie électronique pour obtenir le code de vérification.";
                    this.isInvalid = true;
                } else {
                    this.errors.email = "Votre compte a été bloqué. Veuillez patienter 5 minutes et vérifier votre messagerie électronique pour obtenir le code de vérification.";
                    this.isInvalid = true;
                }
            }
            this.isSubmit = false;
        });
    }
},

        startTyping() {
            if (!this.isDeleting && this.i < this.txt.length) {
                // Forward typing
                this.displayedText += this.txt.charAt(this.i);
                this.i++;
                setTimeout(this.startTyping, this.speed);
            } else if (this.isDeleting && this.i > 0) {
                // Backspacing
                this.displayedText = this.displayedText.slice(0, -1);
                this.i--;
                setTimeout(this.startTyping, this.backspaceSpeed);
            } else {
                // Toggle between typing and backspacing
                this.isDeleting = !this.isDeleting;
                setTimeout(this.startTyping, 1000); // Pause before changing direction
            }
        },
    },
    mounted() {
        this.startTyping();
        setInterval(() => {
            this.showCursor = !this.showCursor; // Blinking cursor effect
        }, 500);
    }

}
</script>
<style lang="scss" scoped>
    @import "../../assets/scss/tailwinds.scss";
    .cursor {
        display: inline-block;
        width: 10px;
        height: 20px;
        background-color: red;
        animation: blink 1s step-start infinite;
    }

    @keyframes blink {
    50% {
        opacity: 0;
    }
    }
    
</style>